import './App.css';

function App() {
  return (
    <div className="App">
        <div className="intro-div">
          <h1 className="name">André Correia Rocha</h1>
          <h3 className="contact">+351925112378</h3>
          <h3 className="contact">+41782162239</h3>
          <h3 className="contact">rochandre@gmail.com</h3>
          <a href="https://www.facebook.com/Mr.Baywatch" className="fa fa-facebook"><span hidden>hidden</span></a>
          <a href="https://www.linkedin.com/in/andrer0cha" className="fa fa-linkedin"><span hidden>hidden</span></a>
          <a href="https://www.instagram.com/andre_r_ocha/" className="fa fa-instagram"><span hidden>hidden</span></a>
          <a href="https://github.com/EngAndreRocha" className="fa fa-github"><span hidden>hidden</span></a>
        </div>
        <div className="portfolio-div">
          <a href="http://clariciaeventus.com">
            <img border="0" alt="Claricia Eventus" src="/clariciaeventus.PNG"/>
          </a>
          <a href="http://eidodotinoco.com">
            <img border="0" alt="Eido do Tinoco" src="/eidodotinoco.PNG"/>
          </a>
        </div>
    </div>
  );
}

export default App;
